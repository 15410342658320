(function (w, d) {
    const button = d.querySelector('.MainNavigation__toggle');

    const navButton = document.querySelector('.MainNavigation__toggle');
    const nav = document.querySelector('.MainNavigation__list');

    if (navButton) {
        navButton.addEventListener('click', () => {
            const exp = navButton.getAttribute('aria-expanded');
            if (exp === 'false') {
                navButton.setAttribute('aria-expanded', 'true');
                nav.classList.add('MainNavigation__list--active');
            } else {
                navButton.setAttribute('aria-expanded', 'false');
                nav.classList.remove('MainNavigation__list--active');
            }
        });
    }
})(window, document);

