/**
 * Sorts a HTML table.
 *
 * @param {HTMLTableElement} table The table to sort
 * @param {number} column The index of the column to sort
 * @param {boolean} asc Determines if the sorting will be in ascending
 */
function sortTableByColumn(table, column, asc = true) {
    const dirModifier = asc ? 1 : -1;
    const tBody = table.tBodies[0];
    const rows = Array.from(tBody.querySelectorAll("tr"));

    // Sort each row
    const sortedRows = rows.sort((a, b) => {
        let aColText = a.querySelector(`td:nth-child(${column + 1})`).textContent.trim();
        let bColText = b.querySelector(`td:nth-child(${column + 1})`).textContent.trim();

        // Regex for finding end dates of date periods
        const regex = RegExp(/(\d{4})[^\d\w](\d{4})/)
        const regexA = regex.exec(aColText);
        const regexB = regex.exec(bColText);

        // If both years have the same end date check for first date if available
        if (regexA && regexB) {
            if (regexA[2] === regexB[2]) {
                aColText = regexA[1];
                bColText = regexB[1];
            } else {
                aColText = regexA[2];
                bColText = regexB[2];
            }
        } else {
            aColText = regexA ? regexA[2] : aColText;
            bColText = regexB ? regexB[2] : bColText;
        }

        if (aColText === bColText) {
            return 0;
        }
        return aColText > bColText ? (1 * dirModifier) : (-1 * dirModifier);
    });

    // Remove all existing TRs from the table
    while (tBody.firstChild) {
        tBody.removeChild(tBody.firstChild);
    }

    // Re-add the newly sorted rows
    tBody.append(...sortedRows);

    // Remember how the column is currently sorted
    table.querySelectorAll("th").forEach(th => th.removeAttribute('aria-sort'));

    const currentth = table.querySelector(`th:nth-child(${column + 1})`);
    if (currentth) {
        currentth.setAttribute('aria-sort', asc ? 'ascending' : 'descending');
    }
}


(function (w, d) {
    document.querySelectorAll(".Table__sort-button").forEach(headerButton => {
        headerButton.addEventListener("click", () => {
            const headerCell = headerButton.parentElement;
            const tableElement = headerCell.parentElement.parentElement.parentElement;
            const headerIndex = Array.prototype.indexOf.call(headerCell.parentElement.children, headerCell);
            const currentSort = headerCell.getAttribute('aria-sort');
            const currentIsAscending = currentSort === 'ascending' ? true : false;

            sortTableByColumn(tableElement, headerIndex, !currentIsAscending);
        });
    });
})(window, document);
