(function (w, d) {
    function replaceMenuButtons(element) {
        const triggerLink = element.querySelector('.MainNavigation__link--sublist');
        let newButton = d.createElement('button');

        newButton.classList = 'MainNavigation__link MainNavigation__link--button reset-button';
        newButton.setAttribute('aria-controls', triggerLink.getAttribute('data-aria-controls'));
        newButton.setAttribute('data-label-submenu', triggerLink.getAttribute('data-label-submenu'));
        newButton.setAttribute('aria-expanded', 'false');

        const spanSubmenu = `<span class="visually-hidden">${newButton.getAttribute('data-label-submenu')}</span>`;
        /*const spanIcon = `<span class="MainNavigation__icon">${triggerLink.querySelector('template').innerHTML}</span>`;*/
        const spanIcon = `<span class="MainNavigation__icon">${triggerLink.querySelector('.MainNavigation__icon').innerHTML}</span>`;
        newButton.innerHTML = `${triggerLink.textContent}${spanIcon}${spanSubmenu}`;

        triggerLink.replaceWith(newButton);

        newButton.addEventListener('click', () => {
            if (newButton.getAttribute('aria-expanded') === 'true') {
                newButton.setAttribute('aria-expanded', 'false');
            } else {
                newButton.setAttribute('aria-expanded', 'true');
            }
        });
    }

    const subMenus = d.querySelectorAll('.MainNavigation__item--sublist');
    subMenus.forEach((subMenu) => {
        replaceMenuButtons(subMenu);
    });


    /**
     * change tabindexes to swap order of Main and Secondary Nav
     * @param nav
     */
    function activateTab(nav) {
        const menuMain = d.getElementById('menu-main');
        menuMain.parentNode.appendChild(menuMain);
    }

    function deactivateTab(nav) {
        const menuMain = d.getElementById('menu-main');
        menuMain.parentNode.insertBefore(menuMain, menuMain.parentNode.firstChild);
    }

    function closeMenus() {
        const openMenus = d.querySelectorAll('.MainNavigation__link--button[aria-expanded = true]');
        let exp = this.getAttribute('aria-expanded');

        openMenus.forEach((button) => {
            button.setAttribute('aria-expanded', 'false');
        });

        this.setAttribute('aria-expanded', exp === 'true' ? 'true' : 'false');
    }

    function addCloseMenus(buttons) {
        buttons.forEach((button) => {
            button.addEventListener('click', closeMenus);
        });
    }

    function removeCloseMenus(buttons) {
        buttons.forEach((button) => {
            button.removeEventListener('click', closeMenus);
        });
    }

    if (w.BreakpointManager) {
        const nav = d.querySelector('.SecondaryNavigation');
        const menubuttons = d.querySelectorAll('.MainNavigation__link--button');

        if (menubuttons) {
            w.BreakpointManager.registerComponent(
                this,
                500,
                null,
                () => {
                    addCloseMenus(menubuttons);
                },
                () => {
                    removeCloseMenus(menubuttons);
                }
            );
        }
        if (nav) {
            w.BreakpointManager.registerComponent(
                this,
                null,
                200,
                () => {
                    activateTab(nav);
                },
                () => {
                    deactivateTab(nav);
                }
            );
        }


    }

})(window, document);
