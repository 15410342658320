(function (w, d) {
    const toggles = d.querySelectorAll('.Toggletip__toggle');
    toggles.forEach((toggle) => {
        toggle.addEventListener('click', () => {
            const status = toggle.getAttribute('aria-expanded');
            toggle.setAttribute('aria-expanded', status === 'true' ? 'false' : 'true');
        });
    })
})(window, document);

